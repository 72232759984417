import React from 'react'
import Layout from "@layout/layout"

import Calendar from "@homepage/calendar";
import {graphql, Link} from "gatsby";
import PostCategory from "@blog/post-category.js"
import html2plaintext from "html2plaintext";
import SEO from "@layout/seo";
import Helmet from "react-helmet";

export default ({data}) => {
  const articles = data.blog.edges.map(({node}) => ({
    frontmatter: node.childMarkdownRemark.frontmatter,
    html: node.childMarkdownRemark.html,
  }));

  const categories = {
    'Opieka w ciąży': [],
    'Ginekologia': [],
    'Badania USG w ciąży': [],
    'Tiliamed': []
  }
  articles.map((article) => {
    for(let cat of article.frontmatter.category) {
      categories[cat].push(article)
    }
  })

  articles.sort(function (a, b) {
    return new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
  })

  return (
    <Layout>
      <SEO
        title={'Ginekologia & Położnictwo & Szkoła rodzenia - Baza wiedzy TiliaMed'}
        description={'Odpowiedzi specjalistów na najważniejsze pytania dot. Ginekologii & Położnictwa & Szkoły rodzenia, np.: Jak wygląda badanie ginekologiczne? Jak przygotować się do ginekologa? Czym jest badanie prenatalne? Itd.'}
      />
      <Helmet>
        <script type="application/ld+json">
        {
          `
          {"@context":"http:\\/\\/schema.org","@type":"WebSite","@id":"#website","url":"https:\\/\\/tiliamed.pl\\/","name":"Tiliamed"}
          `
        }
        </script>
      </Helmet>
      <div className="content">
        <main>
          <div className="page-margin post-index">
            <Calendar />
            <section className="post-index__posts">
              <div className="container">
                <div className="post-index__posts__search hidden-xs">
                  <input type="text" className="js-search-input" placeholder="Wyszukaj..."/>
                </div>
                <div className="visible-xs swipe">
                  przesuń w prawo<br/>
                  <svg width={43} height={16} viewBox="0 0 43 16" xmlns="http://www.w3.org/2000/svg"><title>czytaj dalej
                    arrow</title>
                    <path
                      d="M42.584 6.803c-.015-.015-.01-.04-.025-.056L36.392.371a1.207 1.207 0 0 0-1.739 0 1.3 1.3 0 0 0 0 1.797l4.135 4.275H1.228C.55 6.443 0 7.01 0 7.712c0 .7.55 1.27 1.228 1.27h37.555l-4.13 4.269a1.293 1.293 0 0 0 0 1.792c.241.249.555.376.87.376.314 0 .628-.127.869-.376l6.167-6.377c.256-.274.408-.594.408-.954s-.152-.675-.383-.909z"
                      fill="#9B9B9B" fillRule="evenodd"/>
                  </svg>
                </div>
              </div>
              <div className="post-index__posts__category__container">
                <div className="container">
                  <div className="row js-mobile-carousel owl-carousel">
                    <div className="col-xs-12 col-sm-4">
                      <h3 className="js-posts-category post-index__posts__category post-index__posts__category--0"
                          data-filter=".term-2">
                        Opieka w ciąży ({categories['Opieka w ciąży'].length})
                      </h3>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <h3 className="js-posts-category post-index__posts__category post-index__posts__category--1"
                          data-filter=".term-3">
                        Ginekologia ({categories['Ginekologia'].length})
                      </h3>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <h3 className="js-posts-category post-index__posts__category post-index__posts__category--2"
                          data-filter=".term-4">
                        Badania USG w ciąży ({categories['Badania USG w ciąży'].length})
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="js-posts-container post-index__posts__container">
                  {articles.map((article, key) => {
                    const term4 = article.frontmatter.category.includes('Badania USG w ciąży') ? 'term-4' : ''
                    const term3 = article.frontmatter.category.includes('Ginekologia') ? 'term-3' : ' '
                    const term2 = article.frontmatter.category.includes('Opieka w ciąży') ? 'term-2' : ''
                    return (
                      <div className={`col-sm-4 single-item ${term2} ${term3} ${term4}`}>
                        <div className="post-box">
                          <p className="post-box__category">
                            {article.frontmatter.category.map((category, key) => (
                              <PostCategory category={category} key={key}/>
                            ))}
                          </p>
                          <p className="post-box__title">
                            <Link to={article.frontmatter.slug}>
                              {article.frontmatter.title}
                            </Link>
                          </p>
                          <div className="post-box__content" dangerouslySetInnerHTML={{
                            __html: html2plaintext(article.html).substring(0, 300) + '...'
                          }}/>
                          <Link to={article.frontmatter.slug}
                             className="btn btn-white category-color-3">
                            czytaj artykuł
                          </Link>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query Education {
        blog: allFile(filter: {sourceInstanceName: {eq: "blog"}})  {
            edges {
                node {
                    sourceInstanceName
                    childMarkdownRemark {
                      html
                      frontmatter {
                        title
                        slug
                        category
                        date
                      }
                    }
                }
            }
        }
    }`
